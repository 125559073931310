import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule} from 'ngx-toastr';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { UserService } from './shared/user.service';
import { UserComponent } from './user/user.component';
import { SignInComponent } from './user/sign-in/sign-in.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './routes';
import { AuthGuard } from './auth/auth.guard';
import { HeaderComponent } from './header/header.component';
import { FileSelectDirective, FileDropDirective } from 'ng2-file-upload';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartsModule } from 'ng2-charts';

@NgModule({
  declarations: [
    AppComponent,
    UserComponent,
    SignInComponent,
    HeaderComponent,
    FileSelectDirective,
    FileDropDirective,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
        maxOpened: 2
    }),
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, { useHash: true }),
    FilterPipeModule,
    ChartsModule
  ],
  providers: [
      UserService,
      AuthGuard,
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
