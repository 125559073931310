import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    readonly rootUrl = 'https://api.riyaz.orbo.ai/api';

    constructor(private _http: HttpClient) { }

    userAuthentication(email, password) {
        const data = {
            email: email,
            password: password
        };
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._http.post(this.rootUrl + '/members/login', data, { headers: reqHeader });
    }

    getLogs(access_token) {
        const reqHeader = new HttpHeaders({ 'Content-Type': 'application/json', 'No-Auth': 'True' });
        return this._http.get(this.rootUrl + '/logs/graphUsageLevel' + '?access_token=' + access_token, { headers: reqHeader });
    }

    logout(access_token) {
        return this._http.post(this.rootUrl + '/members/logout?access_token=' + access_token, null);
    }
}
