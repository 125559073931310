import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/user.service';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

    user_id = null;
    access_token = null;

    public barChartOptions: ChartOptions = {
        responsive: true,
        // We use these empty structures as placeholders for dynamic theming.
        scales: { xAxes: [{}], yAxes: [{}] },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: 'end',
          }
        },
        title: {
            display: true,
            // fontColor: 'white',
            fontSize: 30,
            text: 'USAGE / LEVEL'
        }
      };
      public barChartLabels: Label[];
      public barChartType: ChartType = 'bar';
      public barChartLegend = true;
      public barChartPlugins = [];
      public barChartData: ChartDataSets[];
      public barChartColors: Color[] = [
        {
            backgroundColor: 'rgba(250, 65, 67, 0.7)',
            borderColor: 'rgba(250, 65, 67, 1)',
            pointBackgroundColor: 'rgba(255, 255, 255, 0.5)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        {
            backgroundColor: 'rgba(255,164,59,0.7)',
            borderColor: 'rgba(255,164,59,1)',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        },
        {
            backgroundColor: 'rgba(0,170,169,0.7)',
            borderColor: 'rgba(0,170,169,1)',
            pointBackgroundColor: 'rgba(77,83,96,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(77,83,96,1)'
        },
        {
            backgroundColor: 'rgba(27,65,149,0.7)',
            borderColor: 'rgba(27,65,149,1)',
            pointBackgroundColor: 'rgba(148,159,177,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(148,159,177,0.8)'
        }
    ];


    constructor(private _userService: UserService, private _router: Router) { }

    ngOnInit() {
        this.user_id = localStorage.getItem('userId');
        this.access_token = localStorage.getItem('userToken');
        this._userService.getLogs(this.access_token).subscribe((data: any) => {
            this.barChartData = [
                { data: data.output.barChartData[0].data, label: data.output.barChartData[0].label },
                { data: data.output.barChartData[1].data, label: data.output.barChartData[1].label },
                { data: data.output.barChartData[2].data, label: data.output.barChartData[2].label },
                { data: data.output.barChartData[3].data, label: data.output.barChartData[3].label },
            ];
            this.barChartLabels = data.output.barChartLabels;
        });
        this.barChartData = [ {}, {}, {}, {} ];
        this.barChartLabels = [];


    }

    Logout() {
        this.access_token = localStorage.getItem('userToken');
        if (!this.access_token) {
            this._router.navigate(['/login']);
        }
        this._userService.logout(this.access_token).subscribe((data: any) => {
            localStorage.clear();
            this._router.navigate(['/login']);
        });
    }

}
