import { Component, OnInit } from '@angular/core';
import { UserService } from './../../shared/user.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit {

    access_token: string;
    user_id = null;

    constructor(private _userService: UserService, private _router: Router,
        private _toastr: ToastrService, private _activatedRoute: ActivatedRoute) { }

    ngOnInit() { }

    OnSubmit(email, password) {
        this._userService.userAuthentication(email, password).subscribe((data: any) => {
            // console.log(data);
            localStorage.setItem('userToken', data.id);
            localStorage.setItem('userId', data.userId);
            this.access_token = localStorage.getItem('userToken');
            this.user_id = localStorage.getItem('userId');
            // this._userService.getUserInfo(this.user_id, this.access_token).subscribe((response: any) => {
            //     // console.log(response);
            //     const responseJson = JSON.stringify(response);
            //     // console.log(responseJson);
            //     this._router.navigate(['/home']);
            // });
            this._router.navigate(['/dashboard']);
        },
            (err: HttpErrorResponse) => {
                this._toastr.error('Incorrect Username or Password', 'Oops!');
            });
    }
}
