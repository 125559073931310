import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../shared/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    access_token = null;
    isLogin: any;

  constructor( private _router: Router, private _userService: UserService) { }

  ngOnInit() {
      this.isLogin = localStorage.getItem('isLogin');
  }

  Logout() {
    this.access_token = localStorage.getItem('userToken');
    this.isLogin = false;
    if (!this.access_token) {
        this._router.navigate(['/login']);
    }
    this._userService.logout(this.access_token).subscribe((data: any) => {
        localStorage.setItem('isLogin', this.isLogin);
        localStorage.removeItem('userId');
        localStorage.removeItem('userToken');
        this._router.navigate(['/login']);
    });
}

}
